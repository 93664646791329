@import '../../styles/styles';

.modalOverlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: $dark-overlay;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalGeneralContainer {
  width: 90%;
  max-height: 90%;
  overflow-y: auto;
  background-color: white;
  padding: 20px 15px;
  border-radius: 12px;
  max-width: 800px;
  position: relative;
  display: flex;
  flex-direction: column;
  @media (max-width: $tablet) {
    border-radius: 8px;
  }
  &.xl {
    max-width: 1080px;
    width: 90%;
  }
  &.l {
    max-width: 900px;
    width: 90%;
  }
  &.m {
    max-width: 720px;
    width: 90%;
  }
  &.s {
    max-width: 600px;
    width: 100%;
  }
  &.xs {
    max-width: 400px;
    width: 100%;
  }
}

.modalHeader {
  width: 100%;
  position: relative;
  & h1 {
    padding-right: 30px;
    margin-top: 0;
  }
}

.closeIcon {
  position: absolute;
  top: 0;
  right: 0;
  width: 25px;
  height: 25px;
  cursor: pointer;
  &:hover {
    color: $medium-gray;
  }
}

.modalBody {
  flex-grow: 1;
}

.modalFooter {
  margin-top: auto;
  margin-bottom: 0;
  width: 100%;
}
