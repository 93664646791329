@import '../../styles/styles';

.QuantityButton {
  width: 100%;
  height: 45px;
  background-color: white;
  box-shadow: 0px 2px 4px rgba(72, 72, 72, 0.14);
  display: grid;
  grid-template-columns: repeat(3, 33.33333%);
  border-radius: 10px;
}

.button__container,
.quantity__container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-weight: 700;
  color: $base-purple;
  font-size: 16px;
}

.button__container {
  cursor: pointer;
  &:hover {
    color: black;
  }
}
