@import '../../styles/styles';

.multiselect {
  min-width: 50px;
  height: 100%;
  position: relative;
  width: 100%;
}

.main__selector {
  position: relative;
  height: 100%;
  cursor: pointer;
}

.selectedValue__input {
  height: 100%;
  padding: 0 30px 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #b8b8b8;
  border-radius: 10px;
  &.single-line {
    border: none;
    border-bottom: 1px solid #b8b8b8;
  }
}

.toggle__button {
  position: absolute;
  height: 20px;
  width: 20px;
  right: 0;
  top: 50%;
  margin-top: -10px;
  cursor: pointer;
}

.values__container {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  transform: translateY(100%);
  background-color: white;
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #b8b8b8;
  border-top: none;
  z-index: 10;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.value {
  margin-bottom: 0;
  font-size: 14px;
  cursor: pointer;
  border-bottom: 1px solid $medium-gray;
  &:hover {
    background-color: $light-gray;
  }
}
