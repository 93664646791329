@import '../../styles/styles';

.ProductDetailMainCard {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 14px;
  padding: 20px 15px;
  max-width: 1080px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  @media (max-width: $tablet) {
    padding: 10px;
  }
  font-size: 14px;
}

.mainInfoCard__header {
  display: flex;
  align-items: center;
  row-gap: 10px;
  @media (max-width: $tablet) {
    margin-bottom: 20px;
  }
  & .mainInfoCard__productName {
    font-size: 16px;
    font-weight: 700;
    color: $base-purple;
    margin-right: 100px;
    @media (max-width: $tablet) {
      margin-right: 50px;
    }
  }
  & .mainInfoCard__productReference {
    font-weight: 400;
    color: #484848;
    display: flex;
    align-items: center;
    & .copy__button {
      margin-left: 5px;
      display: flex;
      align-items: center;
      cursor: pointer;
      color: $base-purple;
    }
  }
  & .productHeader__buttonsContainer {
    margin-left: auto;
    margin-right: 0;
    display: flex;
  }
}

.productDetail__companyInfoContainer {
  display: flex;
  align-items: center;
  color: $base-purple;
  font-weight: 700;
  text-decoration: underline;
  cursor: pointer;
}

.mainCard__grid {
  display: flex;
  width: 100%;
  column-gap: 10px;
  row-gap: 30px;
  height: 100%;
  @media (max-width: $tablet) {
    flex-wrap: wrap;
  }
}

.info__column,
.images__column {
  display: flex;
  flex-direction: column;
}

.images__column {
  min-width: 350px;
  width: 100%;
  max-width: 60%;
  @media (max-width: $tablet) {
    max-width: 100%;
    min-width: 100%;
  }
}

.info__column {
  min-width: 200px;
  width: 100%;
  max-width: 40%;
  @media (max-width: $tablet) {
    max-width: 100%;
    min-width: 100%;
  }
}

.companyLogo__image {
  max-height: 25px;
  min-height: 25px;
  max-width: 25px;
  min-width: 25px;
  border-radius: 50%;
  border: 1px solid $light-gray;
  margin-right: 10px;
}

.imagesViewer__container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.retailPrice {
  color: #484848;
}

.minPrice__title {
  font-size: 12px;
  color: $base-purple;
}

.available__units {
  &.noStock {
    color: $base-red;
  }
}

.quantityButton__container {
  max-width: 150px;
  min-width: 150px;
  margin-right: 10px;
  @media (max-width: $mobile-l) {
    max-width: 100px;
    min-width: 100px;
  }
}

.addCartButton__container {
  display: flex;
  width: 100%;
  max-width: 350px;
  & .addCartButton {
    width: 100%;
  }
}

.priceInput__container {
  max-width: 200px;
}

.productDetail__productName {
  color: $base-purple;
  font-weight: 700;
}

.productDetail__productName {
  font-size: 18px;
  color: $base-purple;
  font-weight: 500;
}

.productDetail__reference {
  font-size: 14px;
  font-weight: 400;
  color: #484848;
  margin-top: -8px;
}

.productDetail__productPrice {
  font-size: 20px;
  color: $base-purple;
  font-weight: 700;
}

.discountedPrice {
  font-size: 20px;
  color: $base-red;
  font-weight: 700;
  margin-left: 5px;
}

.basePrice {
  font-size: 20px;
  color: $base-purple;
  font-weight: 700;
  &.discounted {
    font-size: 14px;
    text-decoration: line-through;
    color: black;
  }
}
