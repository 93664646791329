@import '../../styles/styles';

.OrderCard {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.section__card {
  background-color: white;
  border-radius: 14px;
  padding: 20px;
  position: relative;
  @media (max-width: $tablet) {
    padding: 20px;
  }
}

.sectionCard__title {
  font-weight: 700;
  color: $base-purple;
  margin-bottom: 10px;
}

.paymentMethod__icon {
  min-width: 25px;
  max-width: 25px;
  min-height: 25px;
  max-height: 25px;
}

.paymentInstructions__container {
  background-color: $light-purple;
  padding: 20px 15px;
  border-radius: 14px;
  margin-top: 10px;
}

.efectyPin__input {
  height: 40px;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0 10px;
  border: 1px solid $medium-gray;
  border-radius: 8px;
  max-width: 200px;
  background-color: white;
}

.copyPin__button {
  height: 30px;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  cursor: pointer;
  & img {
    height: 20px;
    width: 20px;
  }
}

.orderItems__container {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}

.order__product {
  display: flex;
  width: 100%;
  column-gap: 10px;
}

.orderProduct__imageContainer {
  max-width: 60px;
  min-width: 60px;
  max-height: 60px;
  min-height: 60px;
  border-radius: 8px;
  overflow: hidden;
  & .orderProduct__image {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
}

.productName {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.productConfiguration {
  font-size: 12px;
  color: $dark-gray;
}

.paymentMethod__total {
  font-weight: 700;
}

.lastOrder__badge {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px 10px;
  background-color: $light-purple;
  border-bottom-left-radius: 8px;
  color: $base-purple;
  font-weight: 500;
}

.copyOrder__container {
  width: 100%;
  display: flex;
}

.copyOrderLink {
  display: flex;
  align-items: center;
  color: $base-purple;
  margin-left: auto;
  margin-right: 0;
  font-weight: 700;
  text-decoration: underline;
  cursor: pointer;
}

.copyIcon__small {
  min-height: 18px;
  max-height: 18px;
  min-width: 18px;
  max-width: 18px;
  margin-left: 5px;
}

.paymentMethod__label {
  display: grid;
  justify-content: space-between;
  align-items: center;
  grid-template-columns: 30px 1fr 234px;
  @media (max-width: $mobile-l) {
    grid-template-columns: 30px 1fr 50px;
    .paymentMethod__btn_copy_text {
      display: none;
    }
  }
  .paymentMethod__total{
    justify-self: left;
  }
  .paymentMethod__btn_copy{
    text-align: center;
    width: auto;
    border-radius: 10px;
    background: #ECECEC;
    padding: 1em;
    color:#1A022C;
    font-weight: 600;
    img {
      width: 18px;
      height: 18px;
      margin-right: 5px;
    }
  }
}