@import '../../styles/styles';

.OrderStatus {
  position: relative;
  padding-top: 20px;
}

.orderStatus__contentContainer {
  max-width: 800px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
}

.orderStatus__title {
  color: $base-purple;
  font-weight: 700;
  width: 100%;
  text-align: center;
  font-size: 22px;
}

.searchOrder__contaner {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.searchInput__container {
  display: flex;
  column-gap: 15px;
  align-items: center;
  width: 100%;
  justify-content: center;
}

.searchOrder__input {
  max-width: 400px;
  width: 100%;
}

.searchIcon__container {
  display: flex;
  align-items: center;
  margin-right: 5px;
}

.searchOrder__button {
  padding: 0 10px;
}

.orders__container {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  row-gap: 30px;
}

.trackOrdersBox__image {
  margin-bottom: 40px;
}

.lastOrder__container {
  margin-top: 10px;
  color: $base-green;
  text-decoration: underline;
  cursor: pointer;
}

.searchInput__label {
  color: #3F3F3F;
  font-family: Lato;
  width: 70%;
  text-align: center;
  padding: 1em 0;
}
