@import '../../../styles/styles';

.OrderSummaryStep {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.form__card {
  position: relative;
}

.formCard__editButtonContainer {
  position: absolute;
  right: 25px;
  top: 25px;
  cursor: pointer;
  color: $base-purple;
  display: flex;
  align-items: center;
  & .formCard__editButton {
    max-width: 15px;
    min-width: 15px;
    max-height: 15px;
    min-height: 15px;
    margin-left: 5px;
  }
}

.paymentInfo {
  display: flex;
  align-items: center;
}

.paymentInfo__icon {
  min-width: 30px;
  max-width: 30px;
  min-height: 30px;
  max-height: 30px;
  margin-right: 10px;
}
