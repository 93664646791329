.row {
  display: flex;
  padding: 20px 15px;
  flex-wrap: wrap;
  &.center {
    justify-content: center;
    align-items: center;
  }
  &.reverse {
    flex-direction: row-reverse;
  }
  &.no-padding {
    padding: 0;
  }
  &.no-wrap {
    flex-wrap: nowrap;
  }
}

.col {
  display: flex;
  flex-direction: column;
  padding: 10px;
  &.center {
    align-items: center;
    justify-content: center;
  }
  &.no-padding {
    padding: 0;
  }
  &.col-50 {
    width: 50%;
  }
}

.align-right {
  margin-left: auto;
  margin-right: 0;
}

.card {
  background-color: $light-gray;
  border-radius: 12px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}
