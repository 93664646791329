@import '../styles/styles';

.MainLayout {
  height: 100%;
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  max-height: 100vh;
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

.views__container {
  flex-grow: 1;
  max-width: 1080px;
  margin: 0 auto;
  padding: 0px 15px 100px 15px;
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  &.lock {
    overflow-y: hidden;
  }
  @media (max-width: $tablet) {
    padding: 0 10px 100px 10px;
  }
}

.views__overlay {
  position: absolute;
  top: 0;
  left: 0;
  background-color: $dark-overlay;
  height: 100%;
  width: 100%;
  z-index: 100;
}
