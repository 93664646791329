@import '../../../styles/styles';

.CheckoutPaymentMethod {
  display: flex;
  width: 100%;
  align-items: center;
  height: 44px;
  background-color: $light-gray;
  padding: 30px 20px;
  border-radius: 10px;
  cursor: pointer;
}

.cont-payment-option-text{
  display: flex;
  flex-direction: column;
  justify-content: left;
  font-size: 14px;
}
.cont-payment-option-text .payment-options-sub-text{
  color: #AFAFAF;
}
.cont-payment-option-text .payment-options-text{
  color: #3f3f3f;
  font-weight: 600;
}

$payment-method-icon-size: 22px;

.paymentMethod__icon {
  max-width: $payment-method-icon-size;
  min-width: $payment-method-icon-size;
  max-height: $payment-method-icon-size;
  min-height: $payment-method-icon-size;
  margin-right: 15px;
}
