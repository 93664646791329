@import '../../styles/styles';

.CategoriesHorizontalPicker,
.categories__container,
.category__container {
  display: flex;
}

.categories__container,
.category__container {
  align-items: center;
}

.CategoriesHorizontalPicker {
  overflow: hidden;
  position: relative;
  height: 55px;
  align-items: center;
  background-color: transparent;
  margin:10px;
  padding-right:10px;
  margin-bottom: 20px;
}

.categories__container {
  max-width: 100%;
  height: 55px;
}

.category__container {
  margin-right: 20px;
  background-color: white;
  height: 35px;
  width: fit-content;
  border-radius: 20px;
  cursor: pointer;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  &:hover {
    background-color: $light-gray-darker;
  }
  &.current {
    background-color: $base-green;
    color: white;
    font-weight: 700;
    cursor: initial;
    &:hover {
      background-color: $base-green;
    }
  }
}

.category__image {
  min-width: 25px;
  max-width: 25px;
  min-height: 25px;
  max-height: 25px;
  border-radius: 50%;
  margin-right: 10px;
}

.category__name {
  white-space: nowrap;
  font-size: 16px;
  font-weight: 400;
}

.deselectCategory__button {
  height: fit-content;
  display: flex;
  align-items: center;
  margin-left: 5px;
  cursor: pointer;
  &:hover {
    color: $dark-purple;
  }
}
