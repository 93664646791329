@import '../../styles/styles';

.ImageViewer {
  max-width: 100%;
  max-height: 400px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  column-gap: 10px;
}

.verticalImagesPicker {
  max-width: 20%;
  min-width: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 100%;
  overflow-y: auto;
}

.currentImageContainer {
  overflow: hidden;
  max-width: 80%;
  min-width: 200px;
  max-height: 400px;
  display: flex;
  justify-content: center;
}

.currentImage {
  max-width: 100%;
  height: 100%;
  object-fit: scale-down;
  border-radius: 14px;
}

$image-preview-size: 50px;

.imagePreview__container {
  max-width: $image-preview-size;
  min-width: $image-preview-size;
  max-height: $image-preview-size;
  min-height: $image-preview-size;
  margin-bottom: 10px;
  border: 1px solid $light-gray;
  border-radius: 8px;
  cursor: pointer;
  overflow: hidden;
  &.current {
    border: 2px solid $base-purple;
  }
  & .imagePreview {
    max-width: $image-preview-size;
    min-width: $image-preview-size;
    max-height: $image-preview-size;
    min-height: $image-preview-size;
    object-fit: scale-down;
  }
}
