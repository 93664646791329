@import '../../styles/styles';

.Checkout {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px 0;
}

.checkout__layout {
  display: flex;
  column-gap: 20px;
  width: 100%;
  height: 100%;
  @media (max-width: $tablet) {
    flex-direction: column;
    row-gap: 20px;
  }
  & .checkout__stepColumn,
  .checkout__summaryColumn {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  & .checkout__stepColumn {
    min-width: 400px;
    max-width: 70%;
    @media (max-width: $tablet) {
      min-width: 100%;
      max-width: 100%;
    }
  }
  & .checkout__summaryColumn {
    min-width: 315px;
    max-width: 30%;
    @media (max-width: $tablet) {
      min-width: 100%;
      max-width: 100%;
    }
  }
}

.form__card {
  background-color: white;
  width: 100%;
  border-radius: 10px;
  padding: 20px;
  position: relative;
}

.formCard__title {
  font-weight: 700;
  color: $base-purple;
  margin-bottom: 15px;
}

.input__label {
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 5px;
}

.error__label {
  font-size: 12px;
  color: $base-red;
  margin-top: 5px;
}
