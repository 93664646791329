@import '_variables.scss';

.button {
  height: 45px;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  box-shadow: $--box-shadow-1;
  display: flex;
  align-items: center;
  justify-content: center;
  &.large {
    width: 300px;
    @media (max-width: $laptop) {
      width: 200px;
    }
    @media (max-width: $tablet) {
      width: 150px;
      height: 60px;
    }
  }
  &.small {
    width: 150px;
  }
  &.disabled {
    cursor: not-allowed;
  }
}

.primary {
  background-color: $base-green;
  color: white;
  &:hover {
    background-color: $dark-green;
  }
  &.disabled {
    background-color: $medium-green;
    &:hover {
      background-color: $medium-green;
    }
  }
}

.secondary {
  background-color: $secondary-button;
  &:hover {
    background-color: $medium-gray;
  }
  &.disabled {
    &:hover {
      background-color: $secondary-button;
    }
  }
}

.linear-gradient-button {
  background: $base-purple-gradient;
  color: white;
  &:hover {
    background: $medium-purple;
  }
  &.disabled {
    background-color: $medium-purple;
    &:hover {
      background-color: $medium-purple;
    }
  }
}

.link-button {
  background: transparent;
  text-decoration: underline;
  box-shadow: none;
  width: fit-content;
  height: fit-content;
}

.button-icon {
  margin-right: 5px;
  display: flex;
  align-items: center;
}

.border-button {
  background-color: white;
  border: 1px solid #4a5782;
  box-shadow: none;
  color: #4a5782;
  &:hover {
    background-color: $light-purple;
  }
}
