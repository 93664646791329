@import '../../styles/styles';

.BackButton {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.backButton__iconContainer {
  min-width: 20px;
  max-width: 20px;
  min-height: 20px;
  max-height: 20px;
  display: flex;
  align-items: center;
}
