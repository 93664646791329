@import '../../../styles/styles';

.row,
.col,
input {
  width: 100%;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.addressMultiselect {
  width: fit-content;
  min-width: 180px;
  height: 40px;
  margin-right: 10px;
}

.address__row {
  column-gap: 10px;
}

.address__decorator {
  display: flex;
  align-items: center;
}

.normalAddress__container {
  display: flex;
  column-gap: 10px;
  flex-wrap: wrap;
  row-gap: 10px;
  width: 100%;
}
