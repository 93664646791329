@import '../../../styles/styles';

.possibleValues__container,
.possibleValue__container {
  display: flex;
  align-items: center;
}

.possibleValues__container {
  flex-wrap: wrap;
  row-gap: 10px;
}

.possibleValue {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  cursor: pointer;
  margin-right: 10px;
  border: 1px solid $base-gray;
  border-radius: 4px;
  min-width: 30px;
  &.active {
    border: 1px solid $base-purple;
    color: white;
    background-color: $base-purple;
    font-weight: 700;
    &:hover {
      background-color: $base-purple;
      cursor: default;
    }
  }
  &:hover {
    background-color: $light-purple;
  }
}
