@import './styles';

html {
  overflow: hidden;
  box-sizing: border-box;
  font-family: 'Lato', sans-serif;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  max-height: 100vh;
  max-height: -webkit-fill-available;
  height: 100%;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  min-width: 100vw;
  max-width: 100vw;
}

.App {
  max-height: 100vh;
  max-height: -webkit-fill-available;
  max-width: 2560px;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  overflow-x: hidden;
  margin: 0 auto;
  background-color: #f6f6f4;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(165, 165, 165);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(121, 121, 121);
}
