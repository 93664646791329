@import '../../styles/styles';

.CartSummary {
  min-width: 320px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid #e5e5e5;
  padding: 20px 15px;
  row-gap: 10px;
  background-color: white;
}

.cartSummary__title {
  color: $base-purple;
  font-weight: 700;
}

.summary__row {
  width: 100%;
  display: flex;
  &.total {
    font-weight: 700;
    color: $base-purple;
  }
}

.summaryRow__value {
  margin-left: auto;
  margin-right: 0;
}

.summaryValue__discountContainer {
  display: flex;
  column-gap: 5px;
  align-items: flex-end;
  & .originalValue {
    font-size: 12px;
    text-decoration: line-through;
  }
  & .discountedValue {
    color: $base-red;
    font-weight: 700;
  }
}
