@import '../../styles/styles';

.Cart {
  padding-top: 20px;
  @media (max-width: $tablet) {
    padding-top: 10px;
    padding-bottom: 110px;
  }
}

.backButton__container {
  margin-bottom: 20px;
}

.cart__title {
  font-size: 16px;
  color: $base-purple;
  font-weight: 700;
  margin-bottom: 20px;
}

.cart__layout {
  display: flex;
  column-gap: 20px;
  @media (max-width: $tablet) {
    flex-direction: column;
    row-gap: 20px;
  }
}

.cartProducts__container {
  max-width: 70%;
  width: 100%;
  min-width: 400px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  position: relative;
  @media (max-width: $tablet) {
    min-width: 300px;
    max-width: 100%;
  }
}

.cartSummary__container {
  max-width: 30%;
  width: 100%;
  min-width: 320px;
  min-height: 100%;
  position: relative;
  @media (max-width: $tablet) {
    max-width: 100%;
    min-width: 300px;
  }
}

.summaryFloatingContainer {
  position: sticky;
  top: 0;
}

.cartButton__fixedContainer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 0px -6px 10px 0px #bdbdbd;
  padding: 10px 25px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: white;
  & .cartButton__fixedButton {
    width: 100%;
    margin-bottom: 15px;
  }
  & .addMoreProducts__button {
    display: flex;
    width: 100%;
    justify-content: center;
    text-decoration: underline;
    color: $base-purple;
  }
}

.emptyCart__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & .emptyCart__label {
    margin-bottom: 15px;
  }
}
