@import '../../../styles/styles';

.CheckoutSteps {
  display: flex;
  width: 100%;
  align-items: center;
}

.checkoutStep__container {
  display: flex;
  align-items: center;
}

.step__separator {
  margin: 0 10px;
}

.checkoutStep,
.step__separator {
  &.active {
    font-weight: 700;
    color: $base-purple;
  }
}

.checkoutStep {
  cursor: pointer;
}
