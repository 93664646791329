@import '../../../styles/styles';

.storeProductsSkeleton__grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(330px, 330px));
  column-gap: 20px;
  row-gap: 20px;
  justify-content: center;
}

.storeProductsSkeleton__pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
