@import '../../styles/styles';

.CartItemCard {
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
  height: 180px;
  border-radius: 8px;
  display: flex;
  background-color: white;
  overflow: hidden;
  @media (max-width: $tablet) {
    height: 200px;
  }
}

.cartItemCard__image {
  max-height: 180px;
  min-height: 180px;
  max-width: 180px;
  min-width: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  @media (max-width: $mobile-l) {
    max-width: 120px;
    min-width: 120px;
    max-height: 200px;
    min-height: 200px;
  }
}

.cartItemCard__coverImage {
  height: auto;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  @media (max-width: $mobile-l) {
    height: 100%;
  }
}

.cartItemCard__infoContainer {
  padding: 10px;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}

.productInfo__name {
  font-size: 14px;
}

.productInfo__discountedPriceContainer {
  display: flex;
  column-gap: 5px;
  align-items: center;
  & .discountedPrice {
    font-size: 14px;
    color: $base-red;
    font-weight: 700;
  }
  & .originalPrice {
    font-size: 14px;
    font-weight: 700;
    &.discount {
      text-decoration: line-through;
      font-size: 12px;
    }
  }
}

.productConfiguration__variationsContainer {
  display: flex;
  column-gap: 10px;
  width: 100%;
}

.cartItemCard__quantityButtonsContainer {
  margin-top: auto;
  margin-bottom: 0;
}

.cartItemCard__inventoryValue {
  font-size: 12px;
}
