@import '../../styles/styles';

.ProductCard {
  width: 330px;
  height: 150px;
  background-color: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 14px;
  display: grid;
  grid-template-columns: 150px 180px;
  overflow: hidden;
  cursor: pointer;
  &:hover {
    box-shadow: $--box-shadow-2;
  }
}

.productCard__imageContainer {
  max-width: 150px;
  min-width: 150px;
  height: 150px;
  overflow: hidden;
  & .productCard__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.productCard__infoContainer {
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.infoContainer__info {
  display: flex;
}

.info__leftColumn {
  width: 100%;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  & .info__productName {
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.productCard__actionButton {
  height: 20px;
  width: 20px;
  cursor: pointer;
}

.info__rightColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 40px;
  max-width: 40px;
  row-gap: 20px;
}

.productPrice__discounted {
  font-size: 14px;
  color: $base-red;
  font-weight: 700;
}

.productPrice__retailPrice {
  font-size: 14px;
  font-weight: 700;
  &.line-through {
    font-size: 12px;
    text-decoration: line-through;
  }
}

.infoContainer__labels {
  margin-top: auto;
  margin-bottom: 0;
  height: 20px;
  display: flex;
  align-items: center;
}

.productCard__labelsContainer {
  display: flex;
  column-gap: 4px;
}

.product__label {
  font-size: 11px;
  height: 20px;
  width: fit-content;
  display: flex;
  align-items: center;
  padding: 0 5px;
  border-radius: 4px;
  font-weight: 700;
}
