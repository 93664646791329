@import '../../styles/styles';

.modalHeader {
  h1 {
    color: $base-purple;
    font-size: 14px;
    padding-right: 50px;
  }
}

.inputSection {
  position: relative;
}
.searchInput {
  background-color: $light-gray;
  border: 0;
}

.searchIcon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.citiesSection {
  position: relative;
  margin-top: 1em;
  overflow-y: auto;
  min-height: 10em;
  padding-right: 8px;
  &::-webkit-scrollbar {
    background-color: transparent;
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: $medium-gray;
  }
}

.cityRow {
  background-color: transparent;
  border: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 10px;
  cursor: pointer;
  border-bottom: 1px solid $light-gray;
  &:hover {
    background-color: $light-gray;
  }
}

.radioButton {
  background-color: transparent;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
}

.emptySearch {
  color: $base-red;
}

.modalCitySelector__cartItemsNotification {
  display: grid;
  grid-template-columns: 10% 90%;
  margin-bottom: 10px;
  background-color: $light-green;
  color: $dark-red;
  align-items: center;
  border-radius: 8px;
  border: 1px solid $dark-red;
  & .icon__container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
