@import '../../styles/styles';

.CartProductsCardQuantityButtons {
  display: grid;
  grid-template-columns: repeat(3, 30px);
  grid-template-rows: 30px;
  column-gap: 10px;
}

.changeQuantityButton {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  border: 0.5px solid $base-purple;
  border-radius: 4px;
  color: $base-purple;
  font-size: 14px;
  cursor: default;
  &.quantity__button {
    cursor: pointer;
    &:hover {
      background-color: $light-purple;
    }
  }
}
