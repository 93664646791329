@import '../../styles/styles';

.SideNav {
  position: fixed;
  top: 0;
  left: 0;
  max-width: 300px;
  background-color: #1A002D;
  width: 100%;
  height: 100%;
  transform: translateX(-100%);
  z-index: 101;
  transition: all ease 0.5s;
  padding: 20px 15px;
  &.active {
    transform: translateX(0%);
  }
}

.sideNav__header {
  width: 100%;
  display: flex;
  align-items: center;
}

.header__storeName {
  font-size: 16px;
  color: white;
}

.sideNav__closeButton {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  width: 30px;
  height: 30px;
  cursor: pointer;
  & img {
    max-width: 30px;
    min-width: 30px;
    max-height: 30px;
    min-height: 30px;
  }
}

.sideNav__buttonsContainer {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  color: white;
}

.sideNavButtonsContainer__title {
  font-weight: 700;
}

.sideNavButton {
  display: flex;
  align-items: center;
  font-weight: 400;
  cursor: pointer;
}

.sideNavButton__iconContainer {
  display: flex;
  align-items: center;
  margin-right: 15px;
}
