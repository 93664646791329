@import '../../styles/styles';

.storeProducts__grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(330px, 330px));
  column-gap: 20px;
  row-gap: 20px;
  justify-content: center;
  margin-bottom: 20px;
}

.moreProducts__buttonContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}
