@import '../../../styles/styles';

.CheckoutSummary {
  position: relative;
}

.checkoutSummary__summaryCard {
  background-color: white;
  min-width: 315px;
  max-width: 315px;
  padding: 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  @media (max-width: $tablet) {
    min-width: 100%;
    max-width: 100%;
  }
}

.summary__title {
  font-weight: 700;
  color: $base-purple;
}

.summary__row {
  display: flex;
  & .summary__value {
    margin-left: auto;
    margin-right: 0;
    font-weight: 700;
  }
}

.summary__label,
.summary__value {
  &.total {
    font-weight: 700;
    color: $base-purple;
  }
}
