@import '../../styles/styles';

.FilterContainer {
  position: relative;
  border: 1px solid white;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
  background-color: transparent;
  min-height: 34px;
  max-height: 34px;
  .filter__value {
    color: white;
    padding-right: 10px;
  }
  &:hover {
    cursor: pointer;
  }
}

.chevronIcon {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
}

.openFilter__iconContainer {
  color: white;
  min-height: 34px;
  max-height: 34px;
  min-width: 20px;
  max-width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
