@import '../../styles/styles';

.SellerStoreMain {
  max-height: 100%;
  height: 100%;
}

.storeBanner__container {
  width: 100%;
  height: 230px;
  border-radius: 0px 0px 20px 20px;
  display: flex;
  justify-content: center;
  overflow: hidden;
  margin-bottom: 10px;
  @media (max-width: $tablet) {
    height: 120px;
    border-radius: 0px 0px 14px 14px;
  }
  & .storeBanner {
    display: block;
    width: 100%;
    height: auto;
    max-height: 100%;
    max-width: 100%;
    object-fit: cover;
  }
}

.storeInfo__container {
  display: flex;
  width: 100%;
  height: 50px;
  overflow: visible;
  position: relative;
  margin-bottom: 20px;
  justify-content: end;
}

.storeInfo__infoContainer {
  width: 85%;
  display: flex;
  flex-wrap: wrap;
  row-gap: 0;
  height: fit-content;
  align-items: center;
  justify-content: space-between;
  @media (max-width: $mobile-l) {
    align-content: end;
    flex-direction: column;
  }
  @media (max-width: $tablet) {
    width: 80%;
  }
}
.storeInfo__infoContainer_alter {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  row-gap: 0;
  height: fit-content;
  align-items: center;
  justify-content: space-between;
  @media (max-width: $mobile-l) {
    align-content: center;
    flex-direction: column;
  }
}

.storeInfo__logoContainer {
  position: absolute;
  top: 0;
  left: 20px;
  max-width: 100px;
  min-width: 100px;
  max-height: 100px;
  min-width: 100px;
  overflow: hidden;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(-50%);
  margin-right: 10px;
  @media (max-width: $tablet) {
    max-width: 80px;
    min-width: 80px;
    max-height: 80px;
    min-width: 80px;
  }
  & .storeLogo {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.store__name {
  color: $base-purple;
  font-size: 16px;
  font-weight: 700;
  height: 20px;
}

.storeInfo__socialNetworksContainer {
  display: flex;
  column-gap: 10px;
  margin-right: 0;
  height: fit-content;
  @media (max-width: $tablet) {
    margin: initial;
  }
}

.socialNetworkButton {
  min-width: 42px;
  max-width: 42px;
  min-height: 42px;
  max-height: 42px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: white;
  & .socialNetworkButton__logo {
    min-width: 30px;
    max-width: 30px;
    min-height: 30px;
    max-height: 30px;
  }
  @media (max-width: $tablet) {
    min-width: 30px;
    max-width: 30px;
    min-height: 30px;
    max-height: 30px;
    & .socialNetworkButton__logo {
      min-width: 20px;
      max-width: 20px;
      min-height: 20px;
      max-height: 20px;
    }
  }
}

.store__descriptionContainer {
  margin-bottom: 20px;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}

.description__row {
  display: flex;
  align-items: center;
  @media (max-width: $tablet) {
    flex-direction: column;
    align-items: initial;
  }
}

.descriptionRow__label {
  display: flex;
  align-items: center;
  margin-right: 3px;
  color: $base-purple;
  font-weight: 700;
}

.descriptionIcon {
  max-width: 20px;
  min-width: 20px;
  max-height: 20px;
  min-height: 20px;
  margin-right: 3px;
}
