@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Roboto:wght@500;700&display=swap');
@import './variables';

h1,
h2,
h3 {
  font-family: 'Lato', sans-serif;
}

h1 {
  font-size: 24px;
  font-weight: 700;
}

h2 {
  font-size: 20px;
  font-weight: 500;
}

h3 {
  font-size: 18px;
  font-weight: 500;
}

h4 {
  font-size: 14px;
  font-weight: 700;
}

h5 {
  font-size: 14px;
  font-weight: 400;
}

h6 {
  font-size: 12px;
  font-weight: 400;
}

input {
  height: 40px;
  border-radius: 8px;
  border: 0.8px solid #b8b8b8;
  outline: none;
  padding: 0 15px;
  @media (max-width: $tablet) {
    font-size: 16px !important;
  }
  &.large {
    max-width: 335px;
    width: 100%;
  }
  &.medium {
    max-width: 250px;
    width: 100%;
  }
  &.small {
    max-width: 150px;
    width: 100%;
  }
  &::placeholder {
    font-size: 13px;
  }
  &.single-line {
    border: none;
    border-bottom: 1px solid #484848;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  &.error {
    border: 0.8px solid $base-red;
  }
}

p {
  font-size: 14px;
  &.input__label {
    margin-bottom: 5px;
    font-weight: 500;
    font-size: 14px;
  }
  &.success__message {
    color: $base-purple;
  }
}

hr {
  border-top: 1px solid rgb(241, 241, 241) !important;
  width: 100% !important;
}

p {
  margin: 0;
}

.viewTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $base-purple;
  text-align: center;
  width: 100%;
  & h2 {
    color: $base-purple !important;
    margin-bottom: 0 !important;
  }
}
