@import '../../styles/styles';

.Header {
  min-height: 55px;
  max-height: 55px;
  min-width: 100%;
  max-width: 100%;
  background-color: #1a002d;
  box-shadow: 0px 4px 4px rgba(72, 72, 72, 0.1);
}

.headerContent {
  max-width: 1080px;
  width: 100%;
  height: 55px;
  display: flex;
  margin: 0 auto;
  padding: 0 15px;
  @media (max-width: $tablet) {
    padding: 0 10px;
  }
}

.rightButtons__container {
  margin-left: auto;
  margin-right: 0;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  column-gap: 10px;
}

.headerButton {
  min-width: 40px;
  max-width: 40px;
  min-height: 40px;
  max-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 50%;
  color: white;
  position: relative;
}

.cartQuantity {
  position: absolute;
  top: 3px;
  right: 3px;
  background-color: $base-green;
  height: 12px;
  width: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  border-radius: 50%;
}

.leftButtons__container {
  height: 100%;
  display: flex;
  align-items: center;
}

.menuButton__container {
  min-height: 40px;
  max-height: 40px;
  min-width: 40px;
  max-width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
}

.searchBar button {
  min-height: 40px;
  max-height: 40px;
  min-width: 40px;
  max-width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: gray !important;
  cursor: pointer;
  background: white !important;
  border-color: white !important;
}

.searchBar .ant-input-group-addon {
  padding-left: 0px;
  padding-right: 0px;
}

@media (max-width: 768px) {
  .rightButtons__container {
    column-gap: 0px;
  }
}
