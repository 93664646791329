.error-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f0f0f0;
}

.error-title {
  font-size: 4rem;
  color: #555;
  margin-bottom: 20px;
}

.error-message {
  font-size: 1.5rem;
  color: #777;
}

@media (max-width: 768px) {

  .error-container {
    height: 40vh;
  }

  .error-title {
    font-size: 3rem;
  }

  .error-message {
    font-size: 1.2rem;
  }
}

@media (max-width: 480px) {
  .error-title {
    font-size: 2.5rem;
  }

  .error-message {
    font-size: 1rem;
  }
}