@import '../../styles/styles';

.BaseCarousel {
  position: relative;
  height: 100%;
  width: 100%;
  min-height: 50px;
}

.scrollableContent {
  display: flex;
  max-width: 100%;
  overflow-x: hidden;
  padding-bottom: 10px;
  @media (max-width: $tablet) {
    overflow-x: auto;
  }
}

.carouselScrollButton {
  position: absolute;
  height: 35px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $light-gray;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0px 4px 10px rgba(72, 72, 72, 0.25);
  z-index: 5;
  top: 50%;
  margin-top: -17.5px;
  &:hover {
    background-color: $medium-gray;
  }
  &.left {
    left: 2px;
  }
  &.right {
    right: 2px;
  }
}
